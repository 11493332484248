import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import * as Moment from 'vue-moment';
import * as _ from 'lodash';
import Notifications from 'vue-notification';
import BootstrapVue from 'bootstrap-vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Loading from 'vue-loading-overlay';
import router from './router/index';
import App from './App.vue';
import store from './store';
import Amplify from './services/Amplify';
import BlueprintData from './components/blueprint-data.vue';
import BlueprintSearchInput from './components/blueprint-search-input.vue';
import LarvaCognitoWebcomponents from '@larva.io/webcomponents-cognito-login-vue2';

Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('blueprint-data', BlueprintData);
Vue.component('blueprint-search-input', BlueprintSearchInput);

Vue.filter('toFixed', (number, limit = 2) => {
  return Number(number).toFixed(limit);
});

Vue.use(LarvaCognitoWebcomponents);
Vue.use(VueAxios, axios);
Vue.use(Moment);
Vue.use(Notifications);
Vue.use(BootstrapVue);
Vue.use(Loading, {
  backgroundColor: '#fafafa',
  loader: 'bars',
  zIndex: 9999,
  color: '#780bb7',
  isFullPage: true,
});
Vue.config.productionTip = false;
Vue.axios.defaults.baseURL = '/api';
Vue.axios.defaults.timeout = 8000;
Vue.axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest';
Vue.axios.defaults.headers.Accept = 'application/json';

let loader;

function showloader () {
  if (!loader) {
    loader = Vue.prototype.$loading.show();
  }
}
function hideloader () {
  if (loader) {
    loader.hide();
    loader = null;
  }
}

Vue.axios.interceptors.request.use((config) => {
  if (config.noLoading) {
    return config;
  }
  showloader();
  return config;
}, (error) => {
  hideloader();
  return Promise.reject(error);
});

Vue.axios.interceptors.response.use((res) => {
  if (_.has(res, ['headers', 'x-total-count'])) {
    _.set(res, 'count', parseInt(res.headers['x-total-count'], 10));
  }
  hideloader();
  return res;
}, (err) => {
  hideloader();
  return Promise.reject(err);
});

Vue.axios.interceptors.response.use(res => res, (err) => {
  if (err.response && err.response.status === 401) {
    window.location.href = '/auth/login';
  }
  return Promise.reject(err);
});

// Add latest auth access token to every http request
Vue.axios.interceptors.request.use(async (config) => {
  try {
    const token = await Amplify.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`; // User is logged in. Set auth header on all requests
    }
  } catch (err) {
    console.error(err);
  } finally {
    return config;
  }
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
