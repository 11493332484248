<template>
  <div class="content main-content">
    <div class="container container-fluid">
      <transition-page>
        <router-view />
      </transition-page>
    </div>
  </div>
</template>
<style lang="scss">
  .main-content {
    padding-top: calc(130px + 1rem);
  }
</style>

<script>
import TransitionPage from './pransition-page.vue';

export default {
  components: {
    TransitionPage,
  },
};
</script>
