<template>
  <div v-if="orgId" class="container orgs">
    <b-dropdown
      right
      split
      split-variant="outline-primary"
      variant="primary"
      :text="orgName"
    >
      <organization-dropdown-tree :orgs="orgs" @changeOrg="changeOrg" />
    </b-dropdown>
  </div>
</template>
<style lang="scss">
@import "larva/_variables.scss";
.orgs .btn {
  margin: 0 !important;
  padding: 0 .5rem !important;
  border-radius: 0  !important;
  font-size: 0.9rem !important;
}
.orgs {
  background-color: $background-color-step-100;
  padding: 0.1rem 0 0 0 !important;
  text-align: right;
  position: fixed;
  z-index: 1040;
  right: 0;
  left: 0;
}
</style>
<script>
import Vue from 'vue';
import OrganizationDropdownTree from './organization-dropdown-tree.vue';

export default {
  components: {
    OrganizationDropdownTree,
  },
  data () {
    return {
      orgs: [],
      orgId: '',
      orgName: '',
      orgAlias: '',
    };
  },
  async mounted () {
    // use first organization as default, this is users own org
    this.$http.get('/orgs/suborganizations')
      .then((res) => {
        this.orgs = res.data || [];
        if (this.orgs.length) {
          this.orgId = this.orgs[0].id;
          this.orgName = this.orgs[0].name;
          this.orgAlias = this.orgs[0].alias;
        }
      })
      .catch(this.err);
  },
  methods: {
    changeOrg (id, alias, name) {
      this.orgId = id;
      this.orgName = name;
      this.orgAlias = alias;
      // if its users own org
      if (this.orgs[0] && this.orgId === this.orgs[0].id) {
        Vue.axios.defaults.baseURL = '/api';
      } else {
        Vue.axios.defaults.baseURL = `/api/orgs/${id}`;
      }
      this.$router.replace({ path: '/app/dashboard/reload' });
    },
  },
};
</script>
