<template>
  <b-navbar fixed="top">
    <div v-if="!loading" class="container topnav">
      <b-tabs pills no-fade>
        <!--
        <b-tab title="Control" :active="activeRoute('/app/control')">
         <div class="d-flex">
            <div>
              <router-link to="/app/control/units" class='btn btn-link'>
                <fa-icon icon="table" />
                Units
             </router-link>
            </div>
         </div>
        </b-tab>
        --->
        <b-tab title="Dashboard" :active="activeRoute('/app/dashboard')">
          <div class="d-flex">
            <div>
              <router-link to="/app/dashboard/dashboard" class="btn btn-link">
                <fa-icon icon="chart-pie" />
                Dashboard
              </router-link>
            </div>
          </div>
        </b-tab>
        <b-tab v-if="role !== 'USER'" title="Inventory" :active="activeRoute('/app/inventory')">
          <div class="d-flex">
            <div>
              <router-link to="/app/inventory/units" class="btn btn-link">
                <fa-icon icon="table" />
                Units
              </router-link>
              <router-link to="/app/inventory/products" class="btn btn-link">
                <fa-icon icon="file-contract" />
                Products
              </router-link>
            </div>
            <div class="ml-auto">
              <router-link to="/app/inventory/properties" class="btn btn-link">
                <fa-icon icon="building" />
                Properties
              </router-link>
              <router-link to="/app/inventory/types" class="btn btn-link">
                <fa-icon icon="clone" />
                Unit Types
              </router-link>
              <router-link to="/app/inventory/amenities" class="btn btn-link">
                <fa-icon icon="couch" />
                Amenities
              </router-link>
              <router-link to="/app/inventory/taxes" class="btn btn-link ml-auto">
                <fa-icon icon="receipt" />
                Taxes
              </router-link>
              <MeterDataInventoryUtilityTypesLink />
              <InventoryServicesLink />
              <InventoryDevicesLink />
            </div>
          </div>
        </b-tab>

        <b-tab title="Bookings" :active="activeRoute('/app/bookings')">
          <div class="d-flex">
            <div>
              <router-link to="/app/bookings/book" class="btn btn-link">
                <fa-icon icon="shopping-cart" />
                Book a unit
              </router-link>
              <router-link to="/app/bookings/list" class="btn btn-link">
                <fa-icon icon="file-signature" />
                Bookings
              </router-link>
              <BalancesLink />
              <BookingsServiceOrdersLink />
            </div>
            <div class="ml-auto">
              <MessageLink />
              <InvoicesWizardsLink />
            </div>
          </div>
        </b-tab>
        <!--
        <b-tab title="Invoices" v-if="role !== 'USER'" :active="activeRoute('/app/invoices')">
          <div class="d-flex">
            <div>
              <router-link to="/app/invoices/list" class='btn btn-link'>
                <fa-icon icon="file-invoice" />
                Invoices
              </router-link>
              <router-link to="/app/invoices/periodic" class='btn btn-link'>
                <fa-icon icon="file-invoice" />
                Periodic
              </router-link>
            </div>
          </div>
        </b-tab>

-->
        <!-- @TODO: remove module check if we have more modules under data tab --->
        <b-tab v-if="role !== 'USER'" title="Data" :active="activeRoute('/app/data')">
          <div class="d-flex">
            <div>
              <DataMetringDataLink />
            </div>
          </div>
        </b-tab>

        <!-- @TODO: remove module check if we have more modules under data tab --->
        <b-tab v-if="role !== 'USER'" title="Reports" :active="activeRoute('/app/reports')">
          <div class="d-flex">
            <div>
              <router-link to="/app/reports/cico" class="btn btn-link">
                <fa-icon icon="calendar-check" />
                Check in-out
              </router-link>
              <DataMetringReportLink />
            </div>
          </div>
        </b-tab>

        <b-tab v-if="role !== 'USER'" title="Tasks" :active="activeRoute('/app/tasks')">
          <div class="d-flex">
            <div class="ml-auto">
              <router-link to="/app/tasks/jobs" class="btn btn-link">
                <fa-icon icon="cogs" />
                Background jobs
              </router-link>
            </div>
          </div>
        </b-tab>

        <b-tab :active="activeRoute('/app/account')">
          <template v-slot:title>
            <fa-icon icon="user-circle" />
            <span v-if="me && (!me.firstname || me.firstname === '') && (!me.lastname || me.lastname === '')">
              Account
            </span>
            <span v-if="me"> {{ me.firstname }}</span>
          </template>
          <div class="d-flex">
            <div>
              <router-link to="/app/account/profile" class="btn btn-link">
                <fa-icon icon="user" />Profile
              </router-link>
              <router-link to="/app/account/apikeys" class="btn btn-link">
                <fa-icon icon="key" />My API keys
              </router-link>
              <router-link v-if="role === 'ADMIN'" to="/app/account/org" class="btn btn-link">
                <fa-icon icon="building" />Organization
              </router-link>
              <router-link v-if="role !== 'USER'" to="/app/account/companies" class="btn btn-link">
                <fa-icon icon="address-card" />Companies
              </router-link>
              <router-link v-if="role !== 'USER'" to="/app/account/users" class="btn btn-link">
                <fa-icon icon="users" />Users
              </router-link>
              <router-link v-if="role !== 'USER'" to="/app/account/cards" class="btn btn-link">
                <fa-icon icon="id-card-alt" />Access Cards
              </router-link>
              <router-link v-if="role !== 'USER'" to="/app/account/localusers" class="btn btn-link">
                <fa-icon icon="users" />Access Local Users
              </router-link>
              <router-link v-if="role !== 'USER'" to="/app/account/groups" class="btn btn-link">
                <fa-icon icon="user-friends" />Groups
              </router-link>
            <!--- <router-link to="/app/hello" class='btn btn-link'>
              <i class="fas fa-receipt"></i>Billing
            </router-link> ---->
            </div>
            <div class="ml-auto">
              <a href="#" class="btn btn-link" @click.prevent="logout()">
                <fa-icon icon="times" />
                Logout
              </a>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </b-navbar>
</template>

<style lang="scss">
@import "larva/_variables.scss";
@import "larva/_functions.scss";
.nav {
  margin-bottom: -1px;
}
.navbar {
  top: 1.5rem !important;
  padding:0 !important;
  margin: 0 !important;
  opacity: .96;
  max-height: 120px;
}
.topnav {
  .tabs {
    padding:0.3rem 0 0 0 !important;
    width: 100%;
    background-color: $background-color-step-100;
    .nav-item  {
      height: 100%;
    }
    .nav-item:last-child {
      margin-left: auto !important;
      text-align: right;
    }
    .btn {
      border-right: 1px solid lar-color(primary, contrast);
      border-radius: 0;
      opacity: 0.9;
      font-size: 0.8em;
      color: lar-color(primary, contrast);
      padding: 0 0.75rem;
      margin: 0.375rem 0;

      svg {
        font-size: 2em;
        display: block;
        margin: 0 auto;
      }
    }
    .btn:last-child {
      border-right: 0;
    }
    .btn:hover, .btn.active, .btn:active, .btn:target, .btn:focus  {
      text-decoration: none;
      opacity: 1;
    }
    .nav-pills > li > a {
      border-radius: 0;
    }
    .nav-pills {
      .nav-link {
        color: lar-color(primary, base);
        padding: 0.2rem 1rem;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 180px;
        white-space: nowrap;
      }
      .nav-link.active {
        color : lar-color(primary, contrast);
        background-color: lar-color(primary, base);
      }
    }
    .tab-content {
      background-color: lar-color(primary, base);;
      padding : 5px 15px;
    }
  }
}


</style>
<script>
import Amplify from '../services/Amplify';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  // eslint-disable-next-line max-len
  faAddressCard, faIdCardAlt, faCalendarCheck, faKey, faChartPie, faTimes, faBuilding, faUser, faUsers, faUserCircle, faMap, faClone, faCouch, faShoppingBasket, faTable, faFileContract, faUserFriends, faFileSignature, faFileInvoice, faReceipt, faShoppingCart, faCogs,
} from '@fortawesome/free-solid-svg-icons';
import DataMetringDataLink from '../service-modules/meter_data/components/data-metering-data-link.vue';
import DataMetringReportLink from '../service-modules/meter_data/components/data-metering-report-link.vue';
import MeterDataInventoryUtilityTypesLink from '../service-modules/meter_data/components/inventory-utility-types-link.vue';
import InventoryDevicesLink from '../service-modules/larva_devices/components/inventory-devices-link.vue';
import InventoryServicesLink from '../service-modules/services/components/inventory-services-link.vue';
import BookingsServiceOrdersLink from '../service-modules/services/components/bookings-serviceorders-link.vue';
import BalancesLink from '../service-modules/invoices/components/balances-link.vue';
import InvoicesWizardsLink from '../service-modules/invoices/components/wizards-link.vue';
import MessageLink from '../service-modules/booking_notifications/components/message-link.vue';

// eslint-disable-next-line max-len
library.add(faAddressCard, faIdCardAlt, faCalendarCheck, faKey, faChartPie, faTimes, faBuilding, faUser, faUsers, faUserCircle, faMap, faClone, faCouch, faShoppingBasket, faTable, faFileContract, faUserFriends, faFileSignature, faFileInvoice, faReceipt, faShoppingCart, faCogs);


export default {
  components: {
    DataMetringDataLink,
    MeterDataInventoryUtilityTypesLink,
    InventoryDevicesLink,
    InventoryServicesLink,
    BookingsServiceOrdersLink,
    DataMetringReportLink,
    BalancesLink,
    InvoicesWizardsLink,
    MessageLink
  },
  data () {
    return {
      loading: true,
      orgServiceModuleMap: null,
    };
  },
  computed: {
    me () {
      return this.$store.getters.me;
    },
    role () {
      return this.$store.getters.me ? this.$store.getters.me.role : 'USER';
    },
    modules () {
      return this.$store.getters.modules;
    },
  },
  async mounted () {
    if (!this.$store.getters.me) {
      await this.$store.dispatch('FETCH_ME');
    }
    if (!this.$store.getters.modules) {
      await this.$store.dispatch('FETCH_MODULES');
    }
    this.loading = false;
    this.getOrgServiceModules();
  },
  methods: {
    activeRoute (input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some(path => this.$route.path.indexOf(path) === 0); // current path starts with this path string
    },
    logout () {
      Amplify.signOut()
        .then(() => {
          this.$router.push('/auth/login');
        }).catch(err => this.error('Error', err.message));
    },
    getOrgServiceModules () {
      return this.$http.get('org-service-modules')
        .then((res) => {
          this.orgServiceModuleMap = {};
          res.data.forEach((sm) => {
            this.orgServiceModuleMap[sm.serviceModule.key] = sm;
          });
        })
        .catch(this.err);
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
  },
};
</script>
