<template>
  <router-link v-if="modules && !!modules['invoices']" to="/app/bookings/balances" class="btn btn-link ml-auto">
    <fa-icon icon="coins" />
    Balances
  </router-link>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoins } from '@fortawesome/free-solid-svg-icons';

library.add(faCoins);

export default {
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
};
</script>
