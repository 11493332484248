<template>
  <router-link v-if="modules && !!modules['meter_data']" to="/app/data/metering" class="btn btn-link">
    <fa-icon icon="chart-area" />
    Metering
  </router-link>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartArea } from '@fortawesome/free-solid-svg-icons';

library.add(faChartArea);

export default {
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
};
</script>
