<template>
  <router-link v-if="modules && !!modules['services']" to="/app/inventory/services" class="btn btn-link">
    <fa-icon icon="shopping-basket" />
    Services
  </router-link>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';

library.add(faShoppingBasket);

export default {
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
};
</script>
