<template>
  <router-link v-if="modules && !!modules['booking_notifications']" to="/app/bookings/messaging" class="btn btn-link ml-auto">
    <fa-icon icon="envelope" />
    Messageing
  </router-link>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

library.add(faEnvelope);

export default {
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
};
</script>
