
import MainLayout from '../layouts/main.vue';
import DashboardLayout from '../layouts/dashboard.vue';

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified page component must be inside the pages folder
 * @param  {string} name  the filename (basename) of the page to load.
 * */
function page (name) {
  return import(`../pages/${name}.vue`);
}

const routes = [
  {
    path: '/',
    redirect: '/app',
  },
  {
    path: '/privacy',
    redirect: '/privacy/terms',
    component: MainLayout,
    children: [
      {
        path: 'terms',
        component: () => page('terms'),
      },
      {
        path: 'policy',
        component: () => page('policy'),
      },
    ],
  },
  {
    path: '/auth',
    redirect: '/auth/login',
    component: MainLayout,
    children: [
      {
        path: 'login',
        component: () => page('login'),
      },
      {
        path: 'login/:id',
        component: () => page('login'),
      },
    ],
  },
  {
    path: '/app',
    redirect: '/app/dashboard/dashboard',
    component: DashboardLayout,
    children: [
      {
        path: 'account/profile',
        component: () => page('app/account/profile'),
      },
      {
        path: 'account/apikeys',
        component: () => page('app/account/apikeys'),
      },
      {
        path: 'account/org',
        component: () => page('app/account/org'),
      },
      {
        path: 'account/users',
        component: () => page('app/account/users'),
      },
      {
        path: 'account/cards',
        component: () => page('app/account/org-cards'),
      },
      {
        path: 'account/localusers',
        component: () => page('app/account/org-localusers'),
      },
      {
        path: 'account/groups',
        component: () => page('app/account/groups'),
      },
      {
        path: 'account/companies',
        component: () => page('app/account/companies'),
      },
      {
        path: 'control',
        redirect: '/app/control/units',
      },
      {
        path: 'control/units',
        component: () => page('app/control/units'),
      },
      {
        path: 'inventory',
        redirect: '/app/inventory/properties',
      },
      {
        path: 'inventory/properties',
        component: () => page('app/inventory/properties'),
      },
      {
        path: 'inventory/products',
        component: () => page('app/inventory/products'),
      },
      {
        path: 'inventory/types',
        component: () => page('app/inventory/types'),
      },
      {
        path: 'inventory/amenities',
        component: () => page('app/inventory/amenities'),
      },
      {
        path: 'inventory/services',
        component: () => import('../service-modules/services/pages/inventory-services.vue'),
      },
      {
        name: 'inventory.devices',
        path: 'inventory/devices',
        component: () => import('../service-modules/larva_devices/pages/devices.vue'),
      },
      {
        path: 'inventory/taxes',
        component: () => page('app/inventory/taxes'),
      },
      {
        path: 'inventory/units',
        component: () => page('app/inventory/units'),
      },
      {
        path: 'inventory/utilitymetertypes',
        component: () => import('../service-modules/meter_data/pages/utility-meter-types.vue'),
      },
      {
        path: 'reports',
        redirect: '/app/reports/cico',
      },
      {
        path: 'reports/cico',
        component: () =>  page('app/reports/cico'),
      },
      {
        path: 'reports/metering',
        component: () => import('../service-modules/meter_data/pages/metering-report.vue'),
      },
      {
        path: 'bookings',
        redirect: '/app/bookings/list',
      },
      {
        path: 'bookings/timeline',
        component: () => page('app/bookings/timeline'),
      },
      {
        path: 'bookings/list',
        component: () => page('app/bookings/list'),
      },
      {
        path: 'bookings/balances',
        component: () => import('../service-modules/invoices/pages/balances.vue'),
      },
      {
        path: 'bookings/invoices/wizards',
        component: () => import('../service-modules/invoices/pages/wizards.vue'),
      },
      {
        path: 'bookings/messaging',
        component: () => import('../service-modules/booking_notifications/pages/messaging.vue'),
      },
      {
        path: 'bookings/book',
        component: () => page('app/bookings/book-unit-list'),
      },
      {
        path: 'bookings/edit/:id',
        name: 'bookings.booking.edit',
        component: () => page('app/bookings/booking-edit'),
      },
      {
        name: 'bookings.units.book',
        path: 'bookings/units/:id/book',
        component: () => page('app/bookings/book-unit'),
      },
      {
        name: 'bookings.invoice.edit',
        path: 'bookings/:bookingId/invoices/:id/edit',
        component: () => import('../service-modules/invoices/pages/invoice-edit.vue'),
      },
      {
        path: 'bookings/serviceorders',
        component: () => import('../service-modules/services/pages/serviceorders.vue'),
      },
      {
        path: 'tasks',
        redirect: '/app/tasks/jobs',
      },
      {
        name: 'tasks.jobs',
        path: 'tasks/jobs',
        component: () => page('app/tasks/jobs'),
      },
      {
        path: 'dashboard',
        redirect: '/app/dashboard/dashboard',
      },
      {
        path: 'dashboard/reload',
        component: () => page('app/reload'),
      },
      {
        path: 'dashboard/dashboard',
        component: () => page('app/dashboard/dashboard'),
      },
      {
        path: 'data',
        redirect: '/app/data/metering',
      },
      {
        path: 'data/metering',
        component: () => import('../service-modules/meter_data/pages/metering.vue'),
      },
    ],
  },
  {
    path: '*',
    component: MainLayout,
    children: [
      {
        path: '*',
        component: () => page('404'),
      },
    ],
  },
];


export default routes;
