<template>
  <div>
    <b-modal ref="modal" :size="modalSize" no-enforce-focus hide-header>
      <form @submit.prevent="save">
        <slot :item="selectedItem" :state="state" :errors="errors">
          <p>Use default slot for data edit/new and bind values to slot scope item object</p>
        </slot>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="save()">
          Save
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>

    <b-row class="my-1">
      <b-col>
        <slot v-if="noSearch === undefined" name="search">
          <b-input-group>
            <b-form-input v-model="localq" size="50" placeholder="Search..." />
          </b-input-group>
        </slot>
      </b-col>
      <b-col col lg="4">
        <b-button-toolbar class="float-right" key-nav>
          <b-button-group>
            <b-button v-if="noExport === undefined" variant="dark" @click="csv()">
              <fa-icon icon="file-csv" /> CSV
            </b-button>
            <slot name="new">
              <b-button v-if="noNew === undefined" variant="success" @click="newItem">
                <fa-icon icon="plus" /> New
              </b-button>
            </slot>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <b-button-toolbar class="float-right perpage" key-nav>
      <b-dropdown
        v-if="noLimit === undefined"
        right
        variant="outline-primary"
        :text="String(perpage)"
        size="sm"
      >
        <b-dropdown-item @click="changePerPage(10)">
          10
        </b-dropdown-item>
        <b-dropdown-item @click="changePerPage(20)">
          20
        </b-dropdown-item>
        <b-dropdown-item @click="changePerPage(50)">
          50
        </b-dropdown-item>
        <b-dropdown-item @click="changePerPage(100)">
          100
        </b-dropdown-item>
        <b-dropdown-item @click="changePerPage(500)">
          500
        </b-dropdown-item>
        <b-dropdown-item @click="changePerPage(1000)">
          1000
        </b-dropdown-item>
      </b-dropdown>
      <div v-if="selectable !== undefined" right>
        <b-button-group>
          <b-button size="sm" variant="outline-primary" @click="$refs.top.selectAllRows()">
            Select all
          </b-button>
          <b-button size="sm" variant="outline-primary" @click="$refs.top.clearSelected()">
            Clear selected
          </b-button>
        </b-button-group>
      </div>
    </b-button-toolbar>
    <b-table
      ref="top" empty-html="No items to show" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :fields="fields" :items="data"
      :borderless="true" responsive bvwsdwefvt striped hover no-sort-reset
      no-local-sorting show-empty
      :selectable="selectable"
      :select-mode="selectMode"
      @row-selected="onRowSelected"
    >
      <template v-slot:cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <fa-icon :icon="['far', 'check-square']" />
        </template>
        <template v-else>
          <fa-icon :icon="['far', 'square']" />
        </template>
      </template>

      <template v-for="slot in slots()" v-slot:[cell(slot.key)]="{ item }">
        <slot :name="slot.key" :item="item" />
      </template>

      <template v-slot:cell(actions)="{ item }">
        <slot name="actions" :item="item" />
        <a v-if="noEdit === undefined" href="#" class="text-primary" title="Edit" @click.prevent="editItem(item)"><fa-icon icon="edit" />Edit</a>
        <a v-if="noDelete === undefined" href="#" class="text-danger" title="Delete" @click.prevent="removeItem(item)"><fa-icon icon="trash" />Delete</a>
      </template>
    </b-table>

    <b-pagination v-model="currentpage" align="center" :total-rows="totalrows" :per-page="perpage" @change="changePage" />
  </div>
</template>

<script>
import * as FileDownload from 'js-file-download';
import * as _ from 'lodash';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faEdit, faPlus, faTrash, faFileCsv
} from '@fortawesome/free-solid-svg-icons';

import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

library.add(faEdit, faPlus, faTrash, faFileCsv, faCheckSquare, faSquare);

export default {
  props: [
    'apiRoutePath',
    'limitPerPage',
    'fields',
    'newDefaultValues',
    'where',
    'q',
    'params',
    'noNew',
    'noExport',
    'noDelete',
    'noEdit',
    'noActions',
    'noInitFetch',
    'noSearch',
    'noLimit',
    'modalSize',
    'selectable',
    'selectMode'
  ],

  data () {
    return {
      selectedItem: {},
      errors: {},
      localq: null,
      sortBy: null,
      sortDesc: false,
      totalrows: 1,
      currentpage: 1,
      perpage: 10,
      data: [],
    };
  },

  watch: {
    sortBy () {
      this.currentpage = 1;
      this.getData();
    },
    sortDesc () {
      this.currentpage = 1;
      this.getData();
    },
    q () {
      this.search();
    },
    localq () {
      this.search();
    },
    where () {
      this.currentpage = 1;
      this.search();
    },
    params () {
      this.currentpage = 1;
      this.search();
    },
  },

  created () {
    if (this.limitPerPage) {
      this.perpage = this.limitPerPage;
    }
    if (this.noActions === undefined) {
      this.fields.push({
        key: 'actions',
        label: 'Actions',
        class: 'actions',
        sortable: false,
      });
    }
    if (this.selectable !== undefined) {
      //selected
      this.fields.unshift({
        key: 'selected',
        label: '',
        sortable: false,
      });
    }
    if (this.noInitFetch === undefined) {
      this.getData();
    }
  },

  methods: {
    onRowSelected (items) {
      this.$emit('selected', items);
    },
    changePerPage (val) {
      this.perpage = val;
      this.currentpage = 1;
      this.getData();
    },
    csv () {
      const params = this.generateParams(this.currentpage);
      this.$http.get(this.apiRoutePath, {
        params,
        headers: { Accept: 'text/csv' },
        responseType: 'blob',
      }).then((res) => {
        const disposition = res.headers['content-disposition'];
        const filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);
        FileDownload(res.data, filename);
      }).catch(this.err);
    },
    cell (key) {
      return `cell(${key})`; // simple string interpolation
    },
    slots () {
      return this.fields.filter(e => e.slot);
    },
    state (item) {
      return (this.errors[item] ? false : null);
    },
    newItem () {
      this.selectedItem = _.cloneDeep(this.newDefaultValues || {});
      this.errors = {};
      this.$refs.modal.show();
    },
    editItem (item) {
      const { id } = item;
      this.selectedItem = {};
      this.$http.get(`${this.apiRoutePath}/${id}`)
        .then((res) => {
          this.selectedItem = res.data;
          this.errors = {};
          this.$refs.modal.show();
        })
        .catch(this.err);
    },
    save () {
      const { id } = this.selectedItem;
      let promise;
      if (id) {
        promise = this.$http.patch(`${this.apiRoutePath}/${id}`, this.selectedItem);
      } else {
        promise = this.$http.post(`${this.apiRoutePath}`, this.selectedItem);
      }
      promise
        .then(() => {
          this.currentpage = 1;
          this.$refs.modal.hide();
        })
        .then(() => this.getData())
        .catch(this.err);
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    removeItem (item) {
      this.$bvModal.msgBoxConfirm('Are you sure?', {
        size: 'sm',
        okVariant: 'danger',
      }).then((val) => {
        if (!val) {
          return;
        }
        const { id } = item;
        this.$http.delete(`${this.apiRoutePath}/${id}`)
          .then(() => this.getData())
          .catch(this.err);
      });
    },
    search () {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.currentpage = 1;
        this.getData();
      }, 300);
    },
    changePage (page) {
      this.$refs.top.scrollTop = 0;
      this.currentpage = page;
      return this.getData(page);
    },
    generateParams (page) {
      const currentpage = (page || 1) - 1;
      const limit = this.perpage;
      const offset = currentpage * this.perpage;
      const sort = this.sortBy ? `${this.sortDesc ? '-' : ''}${this.sortBy}` : undefined;
      const {
        where, q, localq, params,
      } = this;
      return {
        ...params,
        limit,
        offset,
        sort,
        q: q || localq,
        where,
      };
    },
    getData (page) {
      const params = this.generateParams(page || this.currentpage);
      this.$http.get(this.apiRoutePath, {
        params,
      }).then((res) => {
        this.totalrows = res.count;
        this.data = res.data;
        this.$emit('updated', this.data);
      }).catch(this.err);

    },
  },
};
</script>
