<template>
  <div v-if="orgs">
    <template v-for="org in orgs">
      <b-dropdown-item-button :key="org.alias" @click="changeOrg(org.id, org.alias, org.name)">
        <span v-if="sub">&rdsh;</span> {{ org.name }}
      </b-dropdown-item-button>
      <div v-if="org.orgs && org.orgs.length" :key="org.id" style="padding-left: 10px;">
        <organization-dropdown-tree :orgs="org.orgs" :sub="true" @changeOrg="changeOrg" />
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'OrganizationDropdownTree',
  props: {
    orgs: { type: Array, default: null },
    sub: Boolean,
  },
  methods: {
    changeOrg (id, alias, name) {
      this.$emit('changeOrg', id, alias, name);
    },
  },
};
</script>
