<template>
  <router-link v-if="modules && !!modules['services']" to="/app/bookings/serviceorders" class="btn btn-link">
    <fa-icon icon="file-signature" />
    Service Orders
  </router-link>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFileSignature } from '@fortawesome/free-solid-svg-icons';

library.add(faFileSignature);

export default {
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
};
</script>
