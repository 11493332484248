<template>
  <div class="wrapper">
    <accept-terms />
    <organization-dropdown />
    <menu-component />
    <dashboard-content />
    <footer-component absolute="true" />
  </div>
</template>
<style lang="scss">
@import "larva/_variables.scss";

</style>
<script>
import FooterComponent from '../components/footer.vue';
import MenuComponent from '../components/menu.vue';
import DashboardContent from '../components/dashboard-content.vue';
import OrganizationDropdown from '../components/organization-dropdown.vue';
import AcceptTerms from '../components/accept-terms.vue';

export default {
  components: {
    FooterComponent,
    MenuComponent,
    DashboardContent,
    OrganizationDropdown,
    AcceptTerms,
  },
};
</script>
