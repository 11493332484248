const { sessionStorage, localStorage } = window;

export default new class Amplify {
  async configure () {
    await customElements.whenDefined('lar-cognito-config');
    if (this.config) {
      this.config.remove();
      this.config = undefined;
    }
    const cognitoRegion = sessionStorage.getItem('cognitoRegion');
    const cognitoPoolId = sessionStorage.getItem('cognitoPoolId');
    const cognitoClientId = sessionStorage.getItem('cognitoClientId');
    if (cognitoRegion && cognitoPoolId) {
      this.config = document.createElement('lar-cognito-config');
      this.config.setAttribute('cognito-region', cognitoPoolId);
      this.config.setAttribute('cognito-pool-id', cognitoPoolId);
      this.config.setAttribute('cognito-client-id', cognitoClientId);
      this.config.setAttribute('storage-type', 'session'); //
      window.document.body.appendChild(this.config);
    }
  }

  async signOut (global = false) {
    try {
      await this.isConfigured();
      await this.config.logout(global);
    } catch (err) {
      console.error('Logout eror', err);
    }
  }

  async changePassword (currentPassword, newPasword) {
    await this.isConfigured();
    return this.config.changePassword(currentPassword, newPasword);
  }

  async isConfigured () {
    if (!this.config) {
      return Promise.reject(new Error('Amplify Not Configured'));
    }
    return Promise.resolve();
  }

  async getToken () {
    return this.config ? await this.config.getAccessToken() : null;
  }

  setOrg ({ alias, cognitoRegion, cognitoPoolId, cognitoClientId, cognitoUsernameAttribute } = {}) {
    localStorage.setItem('orgId', alias);
    sessionStorage.setItem('cognitoRegion', cognitoRegion);
    sessionStorage.setItem('cognitoPoolId', cognitoPoolId);
    sessionStorage.setItem('cognitoClientId', cognitoClientId);
    sessionStorage.setItem('cognitoUsernameAttribute', cognitoUsernameAttribute);
    return this;
  }

};
