<template>
  <div />
</template>
<script>
export default {
  async created () {
    if (!this.$store.getters.org) {
      await this.$store.dispatch('FETCH_ORG');
    }
    if (!this.$store.getters.me) {
      await this.$store.dispatch('FETCH_ME');
    }
    if (!this.$store.getters.org.acceptedTermsPrivacy && this.$store.getters.me.role === 'ADMIN') {
      this.termsPopup();
    }
  },
  methods: {
    async termsPopup () {
      const h = this.$createElement;
      const content = h('span', {
        domProps: {
          innerHTML: 'We have added or updated <a href="/privacy/terms" target="_blank">terms and conditions</a> and <a href="/privacy/policy" target="_blank">privacy policy</a>. Please read them carefuly!',
        },
      });
      await this.$bvModal.msgBoxOk(content, {
        title: 'Terms and conditions',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'I AGREE',
        headerClass: 'p-2 border-bottom-0',
        hideHeaderClose: true,
        noCloseOnEsc: true,
        noCloseOnBackdrop: true,
        footerClass: 'p-2 border-top-0',
        centered: true,
      });
      this.$http.patch('org', {
        acceptedTermsPrivacy: true,
      });
    },

  },
};
</script>
