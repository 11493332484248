<template>
  <div class="wrapper wrapper-full-page full-page">
    <router-view />
    <footer-component mode="light" absolute="true" />
  </div>
</template>
<style scoped lang="scss">
@import "larva/_variables.scss";

.wrapper-full-page {
  background: $background-color-step-1000;
}
</style>
<script>
import FooterComponent from '../components/footer.vue';

export default {
  components: {
    FooterComponent,
  },
};
</script>
