<template>
  <multiselect
    v-model="inputVal"
    :label="label"
    :custom-label="customLabel"
    :track-by="trackBy"
    :allow-empty="true"
    :placeholder="placeholder"
    :options="data"
    :internal-search="false"
    :options-limit="10"
    :limit="20"
    :max-height="400"
    :multiple="multiple"
    open-direction="bottom"
    select-label="Select"
    deselect-label="Unselect"
    :hide-selected="multiple"
    :state="state"
    :class="{'is-invalid': (state !== undefined && state !== null) }"
    @open="getData()"
    @search-change="search"
  >
    <template v-slot:noOptions>
      No options available
    </template>
    <template v-slot:noResult>
      No elements found. Change the search query.
    </template>
  </multiselect>
</template>

<style lang="scss">
@import 'larva.scss';
.multiselect .multiselect__tags {
    border-color: $input-border-color;
}
.multiselect .multiselect__placeholder {
  color: $input-placeholder-color;
}
.multiselect.is-invalid .multiselect__tags {
  border-color: lar-color(danger, base);
}
.multiselect__tag-icon, .multiselect__tag-icon:focus, .multiselect__tag-icon:hover,
.multiselect__tag-icon:after,
.multiselect__tag,
.multiselect__option--highlight, .multiselect__option--highlight:after
{
  color: lar-color(secondary, contrast);
  background: lar-color(secondary, base);
}
.multiselect__option--selected.multiselect__option--highlight, .multiselect__option--selected.multiselect__option--highlight:after {
  color: lar-color(danger, contrast);
  background: lar-color(danger, base);
}
.multiselect__content-wrapper {
  min-height: 70px !important;
}
</style>

<script>
import Multiselect from 'vue-multiselect';
import * as _ from 'lodash';

export default {
  components: {
    Multiselect,
  },
  props: ['placeholder', 'apiRoutePath', 'multiple', 'value', 'state', 'customLabel', 'where'],
  data () {
    return {
      setvalue: '',
      trackBy: 'id',
      label: 'name',
      inputVal: null,
      data: [],
    };
  },
  watch: {
    inputVal (val) {
      let value = val;
      if (value && this.trackBy && !this.multiple) {
        value = value[this.trackBy];
      } else if (value && this.trackBy && this.multiple) {
        value = value.map(el => el[this.trackBy]);
      }
      if ((value && this.setvalue !== value && !_.isEmpty(value)) || (_.isEmpty(value) && !_.isEmpty(this.setvalue))) {
        this.setvalue = value;
        this.$emit('input', value);
      }
    },
    value (val) {
      this.checkValue(val);
    },
  },
  mounted () {
    this.checkValue(this.value);
  },
  methods: {
    checkValue (val) {
      // if we have external undefined value (eg. id), then rquest data
      if (val && val !== '' && !this.multiple && this.setvalue !== val) {
        this.$http.get(`${this.apiRoutePath}/${val}`)
          .then((res) => {
            this.inputVal = res.data;
          })
          .catch(err => this.error('Error', err.message));
      } else if (val && val !== '' && this.multiple && this.setvalue !== val) {
        // TODO: fetch using id's?
        this.inputVal = val;
      } else if (this.setvalue !== val) {
        this.inputVal = val;
      }
    },
    search (q) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.getData(q);
      }, 300);
    },
    getData (q) {
      const params = {
        q,
        limit: 20,
      };
      if (this.where) {
        params.where = this.where;
      }
      this.$http.get(this.apiRoutePath, { params })
        .then((res) => {
          this.data = res.data;
        })
        .catch(err => this.error('Error', err.message));
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
  },
};

</script>
