<template>
  <router-link v-if="modules && !!modules['larva_devices']" to="/app/inventory/devices" class="btn btn-link ml-auto">
    <fa-icon icon="microchip" />
    Devices
  </router-link>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons';

library.add(faMicrochip);

export default {
  computed: {
    modules () {
      return this.$store.getters.modules;
    },
  },
};
</script>
