<template>
  <div v-if="loaded" id="app">
    <notifications />
    <transition-page>
      <router-view />
    </transition-page>
  </div>
</template>

<script>
import TransitionPage from './components/pransition-page.vue';
import Amplify from './services/Amplify';

export default {
  components: {
    TransitionPage,
  },
  data () {
    return {
      loaded: false
    };
  },
  async mounted ()  {
    await Amplify.configure().catch(err => {
      console.error(err);
    });
    const token = await Amplify.getToken();
    if (!token) {
      this.$router.replace('/auth/login');
    }
    this.loaded = true;
  }
};
</script>

<style lang="scss">
  @import 'larva.scss';
</style>
