/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    me: undefined,
    org: undefined,
    modules: undefined,
  },
  mutations: {
    me (state, me) {
      state.me = me;
    },
    org (state, org) {
      state.org = org;
    },
    modules (state, modules) {
      state.modules = modules;
    },
  },
  getters: {
    me: state => state.me,
    org: state => state.org,
    modules: state => state.modules,
  },
  actions: {
    FETCH_ME: async (context) => {
      try {
        const { data } = await Axios.get('users/me');
        context.commit('me', data);
      } catch (err) { // eslint-disable-line
        // supress error
      }
    },
    FETCH_ORG: async (context) => {
      try {
        const { data } = await Axios.get('org');
        context.commit('org', data);
      } catch (err) { // eslint-disable-line
        // supress error
      }
    },
    FETCH_MODULES: async (context) => {
      try {
        const { data } = await Axios.get('org-service-modules');
        const moduleMap = {};
        data.forEach((m) => {
          moduleMap[m.serviceModule.key] = m;
        });
        context.commit('modules', moduleMap);
      } catch (err) { // eslint-disable-line
        // supress error
      }
    },
  },
});


export default store;
